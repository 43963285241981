@import 'ngx-toastr/toastr';



/* Common CSS start  */
:root {
    --color-black: #000;
    --color-white: #fff;
    --color-dark-blue: #0E1F33;
    --color-blue: #2A64D8;
    --color-orange: #F27F5B;
    --color-gray: #707070;
    --color-green: #21AC70;
    --body-font: 'Roboto', sans-serif;
  }
  
  * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
/* Common CSS end  */

/* ------Scrollbar CSS Start----- */
/* Firefox */

* {
    scrollbar-width: auto;
    scrollbar-color: var(--color-black) var(--color-blue);
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--color-white);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--color-blue);
    border-radius: 0px;
    border: 0px solid var(--color-white);
  }
  
  /* ------Scrollbar CSS End----- */


 /* Basic CSS start */
 body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-dark-blue);
    font-weight: 500;
    font-family: var(--body-font);
    overflow: hidden;
  }
  
  html,
  body {
    overflow-x: hidden;
  }
  
  p {
    padding: 0;
    color: var(--color-dark-blue);
    font-weight: 400;
  }
  
  p:last-child {
    margin-bottom: 0;
  }
  
  a {
    color: var(--color-blue);
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  
  a:hover,
  a:focus {
    color: var(--color-white);
    text-decoration: none;
    box-shadow: none;
    outline: none;
  }
  
  figure {
    margin: 0;
    padding: 0;
    display: block;
  }
  

 

  img {
    max-width: 100%;
  }
  .form-control {
    height: 45px;
    border: 1px solid #E1E1E1;
    padding: 10px 15px;
    background-color: var(--color-white);
    font-size: 13px;
    color: var(--color-gray);
    font-weight: 500;
    letter-spacing: 0;
    border-radius: 5;
    box-shadow: none !important;
    font-family: var(--body-font);
  }
  
  select.form-control:not([size]):not([multiple]) {
    height: 50px;
  }
  
  textarea.form-control {
    height: 100px;
    resize: none;
  }
  
  .form-control::placeholder {
    color: var(--color-gray);
    font-family: var(--body-font);
  }
  
  .form-control:focus {
    border-color: var(--color-orange);
  }
  
  .sec-space {
    padding: 60px 0;
  }
  
  btn:focus {
    outline: none;
  }
.btn.btn-loading {
    pointer-events: none;
    opacity: 0.6;
}

.btn.btn-loading:before {
    content: "loading...";
}

.btn.btn-loading span {
    display: none !important;
}

  .section-heading{
    max-width: 800px;
    margin: 0 auto 50px;
    text-align: center;
  }
  .section-heading h2{
    font-size: 40px;
    font-weight: 700;
  }
  .section-heading h2 strong{
    color: var(--color-blue);
  }
  .section-heading p{
     opacity: 70%;
  }
  /* Basic CSS end  */




  /* Button start  */
  .btn {
    font-size: 13px;
    padding: 14px 25px;
    min-width: 150px;
    border-radius: 6px;
    text-transform: capitalize;
    box-shadow: none !important;
  }
  
  .btn-primary {
    background: var(--color-green);
    color: var(--color-white);
    border: 1px solid var(--color-green);
  }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background: var(--color-blue);
    border: 1px solid var(--color-blue);
    box-shadow: none;
  }
  .btn-outline-primary{
    background: transparent;
    border: 1px solid var(--color-dark-blue);
    color: var(--color-dark-blue);
  }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active{
    background: var(--color-dark-blue);
    border: 1px solid var(--color-dark-blue);
    color: var(--color-white);
    box-shadow: none;
  }
  .btn-secondary {
    background: var(--color-blue);
    color: var(--color-white);
    border: 1px solid var(--color-blue);
  }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background: var(--color-green);
    border: 1px solid var(--color-green);
    box-shadow: none;
  }
  
  /* Button end  */


  

  /* Header start  */
  #myHeader {
    min-height: 35px;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    box-shadow: 0px 0px 2px #0000001A;
  }
  
  .menumain {
    width: 100%;
    z-index: 9999;
    transition: all .5s ease;
    padding: 0 4%;
  }
  
  .menumain nav.navbar {
    padding-top: 0;
    padding-bottom: 0;
    transition: all .5s ease;
  }
  
  .brand-logo {
    display: inline-block;
    position: relative;
    padding: 5px 0;
  }
  
  .brand-logo img {
    width: 175px;
  }
  
  .navbar-nav {
    flex-wrap: wrap;
  }
  
  .navbar .navbar-collapse ul li a {
    color: #272727;
    font-size: 15px;
    font-weight: 500;
    padding: 24px 5px;
    margin: 0px 15px;
    display: inline-block;
  }
  .navbar-text .btn{
    min-width: auto;
  }
  .navbar-text .btn:first-of-type{
    color: #272727;
    font-size: 14px;
    font-weight: 400;
    padding: 0 14px;
    border: none !important;
    outline: none !important;
  }
  .navbar-text .btn:last-of-type{
    padding: 10px 26px;
    background: #EBF2FF;
    font-size: 14px;
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
}
.navbar-text .btn:last-of-type:hover, .navbar-text .btn:last-of-type:focus, .navbar-text .btn:last-of-type:active{
    background: var(--color-blue);
    color: var(--color-white);
}
  /* Header end   */

  /* styke menu  */
.sticky .menumain {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    background-color: #ffffff;
    -webkit-animation-name: animationFade;
    -o-animation-name: animationFade;
    animation-name: animationFade;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  }
  
  /* menu fixd animation */
  
  @-webkit-keyframes animationFade {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @-o-keyframes animationFade {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      -o-transform: none;
      transform: none;
    }
  }
  
  @keyframes animationFade {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      -o-transform: none;
      transform: none;
    }
  }
  
  /* menu animation end */



  /* ------------- Banner Section Start ------------- */

.banner-main{
    background: url(./assets/images/banner-bg.jpg) no-repeat center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
  }
  .banner-text {
    padding: 150px 0 40px;
  }
  .banner-main h1 {
    font-size: 48px;
    color: var(--color-dark-blue);
    font-weight: 700;
  }
  .banner-text h1 strong{
    color: var(--color-blue);
    font-weight: inherit;
  }
  .banner-text p{
    padding: 0 50px;
    opacity: 70%;
  }
  
  /* ------------- Banner Section End ------------- */
  
  
  
  
  /* ------------- USP Section start ------------- */
  .usp{
    overflow: hidden;
  }
  .usp .section-heading p{
    padding: 0 10%;
  }
  .usp-img{
    margin-bottom: 30px;
  }
  .usp-wrapper .nav-link{
    padding: 25px 18px;
    text-align: left;
    font-size: 18px; 
    color: var(--color-dark-blue);
    font-weight: 600;
    border-radius: 12px;
    opacity: 0.6;
    display: flex;
    align-items: center;
  }
  .feature-text ul, .usp-wrapper .tab-content ul{
    padding: 0;
    list-style: none;
    color: #6F7880;
  }
  .usp-wrapper .tab-content ul li, .feature-text ul li{ 
    background: url(../src/assets/images/check.png) no-repeat left top; 
    margin-bottom: 12px; 
    line-height: 25px;
    min-height: 25px;
    padding-left: 30px;
  }
  .usp-wrapper .nav-link.active{
    opacity: 1;
    background: var(--color-white);
    color: var(--color-dark-blue);
    box-shadow: 0 6px 20px #00000019 ;
  }
  .usp-wrapper .usp-tab img{
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin-right: 14px;
  }
    .usp-row [class="col-"]:first-child{
        flex: 0 0 42%;
    }
    .usp-row [class="col-"]:last-child{
        flex: 0 0 53%;
    }
    .usp::before {
        content: "";
        position: absolute;
        right: -190px;
        background: url(./assets/images/sky-patch.svg) no-repeat center;
        background-size: cover;
        height: 100%;
        width: 40%;
        filter: blur(8px);
        z-index: -1;
        opacity: 0.6;
    }
    .usp::after {
        content: "";
        position: absolute;
        top: 35%;
        left: -285px;
        background: url(./assets/images/purple-patch.svg) no-repeat center;
        background-size: cover;
        height: 70%;
        width: 40%;
        filter: blur(10px);
        z-index: -1;
        opacity: 0.6;
    }

  /* ------------- USP Section End ------------- */




  /* ------------- Feature Section start ------------- */

.features .section-heading p{
    padding: 0 16%;
}
.features h5, .section-heading h5{
    color: var(--color-orange);
    letter-spacing: 1.4px;
    font-size: 12px;
    background: #fef2ee;
    padding: 8px 16px;
    border-radius: 18px;
    width: fit-content;
    margin: 0 auto 14px ;
}
.feature-img img{
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}  
.feature-text{
    padding: 40px;
}

.feature-text h3{
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 20px;
}
.feature-text p{
    opacity: 70%;
}
.feature-row [class="col-"]:first-child{
    flex: 0 0 53%;
}
.feature-row [class="col-"]:last-child{
    flex: 0 0 47%;
}
.feature-row:nth-child(even){
    flex-direction: row-reverse;
    padding: 80px 0;
}
  
  /* ------------- Feature Section End ------------- */




  /* ------------- Cloud Section start ------------- */
.cloud-wrapper{
    background: #2A64D8;
    border-radius: 12px;
    position: relative;
    padding: 40px 0 40px 40px;
    z-index: 1;
}
.cloud-wrapper::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: url(./assets/images/cloud-pattern.png) no-repeat center;
    background-size: cover;
    z-index: -1;
}
.cloud-wrapper h3{
    color: var(--color-white);
    font-size: 41px;
    font-weight: 300;
}
.cloud-wrapper h3 strong{
    font-weight: 500;
}
.cloud-text{
    background: var(--color-white);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 10px 30px #0000001A;
}
.cloud-text p{
    font-size: 20px;
    line-height: 28px;
}
  /* ------------- Cloud Section End ------------- */



  /* Feature page start  */

  .feature-page{
    padding-top: 120px !important;
  }
  .faq .section-heading p{
    padding: 0 16%;
  }
  .faq {
    border-top: 1px solid #0E1F3310;
  }
  .faq-wrapper .accordion-button:not(.collapsed) {
    color: var(--color-dark-blue);
    background-color: transparent;
    box-shadow: none;
    font-size: 22px;
    font-weight: 500;
    padding-left: 0;
    padding-bottom: 0;
    opacity: 1;
  }
  .faq-wrapper .accordion-item {
    border: none;
    border-top: 1px solid rgba(0,0,0,.125);
  } 
  .faq-wrapper .accordion-item:last-child{
    border-bottom: 1px solid rgba(0,0,0,.125);
  }
  .faq-wrapper .accordion-body {
    padding: 1rem 1.25rem 30px 0;
  }
  .faq-wrapper .accordion-body p{
    color: var(--color-dark-blue);
    opacity: 65%;
  }
  .faq-wrapper .accordion-button {
    padding: 30px 22px 30px 0;
    font-size: 22px;
    font-weight: 500;
    color:var(--color-dark-blue);
    opacity: 65%;
  }
  .faq-wrapper .accordion-button::after{
    display: none;
  }
  .faq-wrapper .accordion-button:focus {
    border: none;
    box-shadow: none;
  }


  /* ------------- Footer Section start ------------- */
.footer-main{
    background: var(--color-dark-blue);
    margin-top: 110px;
}
.footer-top{
    padding: 2px;
    border-bottom: solid 1px #ffffff25;
}
.footer-wrapper{
    background: #F1F6FF;
    padding: 30px 70px;
    border-radius: 6px;
    margin-top: -84px;
}
.footer-wrapper h4{
    font-size: 36px;
}
.footer-wrapper p{
    opacity: 60%;
}
.footer-menu{
    padding-top: 40px;
}
.ft-social ul li {
    margin: 0 14px;
}

.ft-social ul li a {
    color: var(--color-white);
    font-size: 16px;
    border-radius: 50%;
    text-align: center;
    background: #ffffff20;
    height: 34px;
    line-height: 36px;
    width: 34px;
    transition: all 0.4s;
}

.ft-social ul li a:hover {
    color: var(--color-white);
    background: var(--color-blue);
    transition: all .4s;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -ms-transition: all .4s;
    -moz-transition: all .4s;
}
.ft-social img{
    max-width: 42%;
}
.quick-link-box ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0;
    display: inline-block;
}
.quick-link-box{
    padding: 30px 0;
}
.quick-link-box ul li {
    display: inline;
    margin: 0 30px;
}
.quick-link-box ul li a {
    text-decoration: none;
    color: rgb(255 255 255 / 70%);
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    letter-spacing: 0.2px;
    transition: all .4s;
}
.quick-link-box ul li a:hover {
    color: var(--color-blue);
    transition: all .4s;
}
.footer-bottom {
    padding: 15px 0;
  }
  
  .footer-bottom p{
    color: #ffffff40;
    margin-bottom: 0;
    font-size: 12px;
  }
  .footer-bottom p a{
    color: inherit;
  }
  .footer-bottom p a:hover{
    text-decoration: underline;
  }
  .footer-bottom ul li{
    display: inline-block;
    margin: 0 20px;
  }
  .footer-bottom ul li a {
    text-decoration: none;
    color: #ffffff40;
    font-size: 12px;
    vertical-align: top;
    line-height: 16px;
    font-weight: 300;
    letter-spacing: 0.2px;
    transition: all .4s;
}
.hrm-video video{max-width: 100%; width: 100%;}

/* Support Page start  */
.support-head .section-heading{
  margin-bottom: 80px;
}
.support-head{
  background: #FCFAF4;
  padding: 90px 4% 0 4%;
}
.search-field .form-control{
  height: 60px;
  font-size: 16px;
  padding: 10px 12px 10px 32px;
}
.search-field{
  margin: -90px auto 52px auto;
  box-shadow: 0 5px 5px #292F341A;
}
.search-field .form-control::placeholder{
  color: var(--color-dark-blue);
  opacity: 60%;
}
.search-icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  background: none;
  border: none;
}
.search-icon img{
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.search-icon:focus, .search-icon:active{
  border: none;
}
.help-wrapper .accordion-button:not(.collapsed) {
  color: var(--color-dark-blue);
  background-color: transparent;
  box-shadow: none;
  font-size: 20px;
  font-weight: 500;
  opacity: 1;
}
.help-wrapper .accordion-body {
  padding: 12px 32px 20px;
}
.help-wrapper .accordion-body p{
  color: var(--color-dark-blue);
  opacity: 60%;
}
.help-wrapper .accordion-button {
  padding: 20px 32px 20px;
  font-size: 20px;
  font-weight: 500;
  color:var(--color-dark-blue);
  /* opacity: 60%; */
}
.help-wrapper .accordion-button::after{
  display: none;
}
.help-wrapper .accordion-button:focus {
  border: none;
  box-shadow: none;
}





.support-facility{
  background: #E3F7F8;
}
.support-box{
  background: var(--color-white);
  border-radius: 12px;
  padding: 30px;
  border: 1px solid #DCE4F4;
  box-shadow: 0 6px #0000000d;
  height: 100%;
}
.support-box h4{
  font-size: 20px;
  line-height: 28px;
  margin: 0 20px 20px 0;
}
.support-box p{
  color: #6F7880;
}
.sup-row{
  row-gap: 30px;
}

.talk-to-us{
  padding: 105px 0 75px 0;
}
.talk-to-us h3{
  font-size: 32px;
  margin-bottom: 20px;
}
.talk-to-us p{
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}
.orange-btn {
  padding-left: 0;
  color: var(--color-orange);
  font-size: 18px;
  font-weight: 500;
}
.orange-btn i{
  font-size: 28px;
  transition: 0.2s ease-in-out;
}
.orange-btn:hover i{
  margin-left: 10px;
}
.orange-btn:hover, .orange-btn:active, .orange-btn:focus{
  color: var(--color-orange);
}
.talk-box{
  padding-right: 52px;
}
/* About */
.topspace{padding-top: 120px !important;}
.about-page{
  background: url(./assets/images/banner-bg.jpg) no-repeat center;
    background-size: cover;
}
.form-wrap{

  position: relative; 
  z-index: 1;
}
.form-wrap form{
  background-color: #f0f5ff;
  padding: 45px 30px;
  border-radius: 16px;
}
.form-wrap::before {
  content: "";
  width: 150px;
  height: 150px;
  position: absolute;
  left: -30px;
  bottom: -30px; 
  background: url(./assets/images/dot-pattern.png) repeat top right;
  opacity: 0.2;
  background-size: 15px; 
  z-index: -1;
}
.form-wrap::after {
  content: "";
  width: 150px;
  height: 150px;
  position: absolute;
  right: -30px;
  top: -30px; 
  background: url(./assets/images/dot-pattern.png) repeat top right;
  opacity: 0.2;
  background-size: 15px; 
  z-index: -1;
}
.mw100{max-width: 100%;}
.section-heading.mw100{margin-bottom: 30px;}
/* ------------- Footer Section End ------------- */


.usp-scroll {
  height:500px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
.usp-scroll::-webkit-scrollbar {
    display: none;
}

.cloud.sec-space .container {
  padding-right:200px;
}

.cloud.sec-space .container .cloud-text {
  margin-right:-200px;
}

@media (min-width: 1400px){
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1200px;
}
}
/* Responsive Css start here */
/* 1200 */
@media screen and (max-width:1400px){
  
}
/* 1170 */
@media screen and (max-width:1199px){
  .banner-main h1 { font-size: 40px; line-height: normal; }
  .section-heading h2{ font-size: 34px; line-height: normal; }
  .feature-text h3{font-size: 30px; line-height: normal;}
}
/* 992 */
@media screen and (max-width:1169px){
  .feature-row:nth-child(even) { padding: 60px 0; }
  .footer-wrapper h4 { font-size: 30px; line-height: normal; }
  .banner-text p{padding: 0;}
  .footer-wrapper{padding: 30px 50px;}
  .support-box h4 {
    font-size: 17px;
    line-height: 23px;
    margin: 0 0 15px;
  }
  .support-box{padding: 20px;}
  .talk-to-us h3 {
    font-size: 23px;
    margin-bottom: 20px;
  }
  .talk-box .btn{padding: 0; font-size: 15px; margin-top: 10px;}
  .talk-to-us p {
    font-size: 16px;
    line-height: 25px; 
  }
}
/* 768 */
@media screen and (max-width:991px){
  .container {max-width: 90%;}
  .navbar-toggler{ width: 42px; height: 38px; margin-left: 20px; padding: 0; outline: none !important; border-color: #2a64d8; box-shadow: none !important; background-color: #ebf2ff; }
  .navbar-collapse{position: fixed; left: -230px; width: 230px; background-color: #fff; padding: 50px 30px; top: 0; right: 0; filter: drop-shadow(2px 4px 16px #ccc); display: block !important; height: 100vh; overflow-y: auto; transition: all 300ms ease-in-out; -webkit-backdrop-filter: blur(3px); backdrop-filter: blur(3px);}
  .navbar-collapse.show{ left: 0; }
  .navbar-text .btn:last-of-type{ padding: 7px 17px; }
  .navbar-text{margin-left: auto;}
  .banner-text { padding: 110px 0 30px; }
  .banner-main h1 { font-size: 36px; line-height: normal; }
  .btn{ padding: 0 20px; height: 38px; line-height: 38px; min-width: 125px; }
  .navbar-text .btn:last-of-type{ padding: 0 20px !important; }
  .navbar .navbar-collapse ul li{width: 100%;}
  .navbar .navbar-collapse ul li a{display: block; padding: 15px 0; border-bottom: 1px solid rgb(0 0 0 / 4%);margin: 0; }
  .navbar .navbar-collapse ul li a:hover{background-color: #fff;}
  .usp-wrapper .nav-link { padding: 18px 12px; font-size: 16px; }
  .banner-main{margin-bottom: 0;}
  .banner-text .btn{padding: 0 10px;}
  .section-heading h2 { font-size: 28px; line-height: normal; }
  .feature-text h3 { font-size: 22px; line-height: normal; }
  .sec-space { padding: 35px 0; }
  .section-heading{margin-bottom: 30px;}
  .hrm-video{padding-bottom: 0;}
  .feature-text { padding:20px 0 0;}
  .feature-row:nth-child(even) { padding: 30px 0; }
  .cloud-wrapper{padding: 25px; text-align: center;}
  .cloud-wrapper h3 { font-size: 26px; }
  .cloud-text{padding: 20px;}
  .cloud-text p { font-size: 17px; line-height: 23px; }
  .footer-wrapper{text-align: center;}
  .ftopimg img{ width: 180px; }
  .footer-wrapper h4{font-size: 27px; line-height: normal;}
  .footer-bottom ul li { margin: 0 10px; }
  .footer-bottom ul li a, .footer-bottom p{font-size: 10px;}
  .quick-link-box ul li { margin: 0 20px; }
  .features{padding-bottom: 0;}
  .menulogo{max-width: 150px; margin-bottom: 15px;}
  .usp-tab{flex-direction: initial !important;}
  .usp-wrapper .nav-link{min-width:100%; flex: 1;}
  .s-vector{text-align: center !important; margin-bottom: 10px;}
  .s-vector img{max-width: 200px;}
  .support-head .section-heading {margin-bottom: 20px;}
  .search-field{margin: 15px auto 20px;}
  .talk-to-us .row{grid-row-gap: 20px;}
  .talk-to-us h3{margin-bottom: 10px;}
  .talk-box .btn{margin-top: 0;}
  .talk-to-us{padding: 35px 0 10px;}
  .cloud.sec-space .container {padding-right:15px;}
  .cloud.sec-space .container .cloud-text {margin-right:0;}
}
/* 576 */
@media screen and (max-width:767px){
  .footer-bottom{flex-direction: column; padding: 10px 0; text-align: center;}
  .quick-link-box { padding: 25px 0 15px; }
  .feature-text h3{margin-bottom: 15px;}
  .usp-img{margin-bottom: 20px;}
  .usp-wrapper p{font-size: 13px; line-height: 20px;}
  .usp-wrapper .usp-tab img { width: 38px; height: 38px; object-fit: contain; margin-right: 9px;}
  .usp-wrapper .nav-link { padding: 15px 12px; font-size: 15px; }
  .banner-main h1 { font-size: 25px; line-height: normal;}
  .section-heading h2 { font-size: 23px; line-height: normal; }
  .features .section-heading p { padding: 0; }
  .cloud-wrapper h3 { font-size: 22px; }
  .footer-wrapper h4 { font-size: 23px; line-height: normal;}
  .footer-wrapper { padding: 25px 25px; }
  .usp .section-heading p { padding: 0;}
  .usp-wrapper .nav-link { padding: 8px 7px; font-size: 15px;}
  .usp-wrapper .usp-tab img { width: 34px; height: 34px; }
  .section-heading { margin-bottom: 20px; }
  .hrm-video{padding: 0;}
  .faq-wrapper .accordion-button { padding: 15px 22px 15px 0 !important; font-size: 16px !important; }
  .faq-wrapper .accordion-body { padding: 0 1.25rem 15px 0; }
  .faq .section-heading p { padding: 0;}
  .feature-page { padding-top: 80px !important;}
  .faq{padding-bottom: 10px !important;}
  .usp-scroll {height:auto;}
}
/* 480 */
@media screen and (max-width:575px){
  body { font-size: 13px; line-height: 19px; }
  .brand-logo{max-width: 120px;}
  .navbar-toggler{margin-left: 12px;} 
  .help-wrapper .accordion-body {
    padding: 12px 21px 20px;
  }
  .help-wrapper .accordion-button{font-size: 16px !important;padding: 14px 18px 14px;}
  .topspace {
    padding-top: 90px !important;
}
  
}
/* 360 */
@media screen and (max-width:479px){
  .navbar-text .btn:last-of-type { padding: 0 10px !important; font-size: 12px; height: 34px; line-height: 34px; }
  .brand-logo{margin-right: 0;}
  .brand-logo img { width: 91px; } 
  .navbar-toggler { width: 37px; height: 34px; margin-left: 9px;}
  .menumain{padding: 0;}
  .banner-text { padding: 70px 0 30px; }
  .quick-link-box ul li { margin: 0 16px; }
  .footer-wrapper h4 { font-size: 19px; line-height: normal;}
  .section-heading h2 { font-size: 21px; line-height: normal;}
  .cloud-text p { font-size: 14px;  line-height: 19px;}
  .feature-text h3 { font-size: 20px; line-height: normal;}
  .cloud-text { padding: 20px 10px;}
  .clud-img img{max-width: 100px; margin: 0 auto;}
  .ftopimg img { width: 140px; }
  .navbar-collapse{padding: 30px 20px;}
  .usp-wrapper .nav-link { padding: 6px 7px; font-size: 11px; line-height: normal; }
  .usp-wrapper .usp-tab img { width: 26px; height: 26px; }
  .form-wrap form{padding: 25px;}
}
/* 320 */
@media screen and (max-width:359px){
  .navbar-text .btn:first-of-type { font-size: 13px; padding: 0 9px 0 0; }
  .navbar-text .btn:last-of-type{font-size: 11px;}
  .quick-link-box ul li { margin: 0 12px; }
  .footer-wrapper h4 { font-size: 18px; }
}
